/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from 'react';
/* eslint-disable no-unreachable */
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
  Zoom
} from '@mui/material';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PageHeader from 'src/components/PageHeader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { duration, VehicleCategory } from 'src/enums';
import EnumAutocomplete from 'src/components/FormInputs/BrandAutocomplete/EnumAutoComplete';
import BrandInput from 'src/components/FormInputs/BrandInput';
import SubSection from 'src/components/SubSection';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import BulkUploadModal from 'src/components/BulkUploadModal';
import RTOAutoComplete from 'src/components/FormInputs/BrandAutocomplete/RTOAutoComplete';
import CSVuploadManual from 'src/components/CSVcomponent/CSVUploadManual';
import DateTimeService from 'src/utils/DateTimeService';
import AppConfig from 'src/flavours/index.flavour';
import useAuth from 'src/hooks/useAuth';
import BrandDatePicker from 'src/components/FormInputs/BrandDatePicker';
import ActivationService from 'src/services/Activation.service';
import StateAutoComplete from 'src/components/FormInputs/BrandAutocomplete/StateAutoComplete';
import useQueryParams from 'src/hooks/useQueryParams';
// import OrganizationAutocomplete from 'src/components/FormInputs/BrandAutocomplete/OrganizationAutoComplete';
import IMEIAutoComplete from 'src/components/FormInputs/BrandAutocomplete/IMEIAutoComplete';
import UserService from 'src/services/User.service';
import toast from 'react-hot-toast';

import ImageUploader from '../imageUploader';

const CardActionsWrapper = styled(Card)(
  ({ theme }) => `
       background: ${theme.colors.alpha.black[5]};
       box-shadow: none;
       margin: 0 ${theme.spacing(3)};
  `
);

const tableConfig = [
  {
    Header: 'Category',
    accessor: 'category',
    required: true
  },
  {
    Header: 'Registration Number',
    accessor: 'registrationNumber',
    required: true
  },
  {
    Header: 'Engine No',
    accessor: 'engineNo'
  },
  {
    Header: 'Chassis No',
    accessor: 'chassisNo'
  },
  {
    Header: 'Registration Date',
    accessor: 'regDate'
  },
  {
    Header: 'RTO',
    accessor: 'rto',
    required: true
  },
  {
    Header: 'RTO Code',
    accessor: 'rtoCode',
    required: true
  },
  {
    Header: 'Make',
    accessor: 'make'
  },
  {
    Header: 'Model',
    accessor: 'model'
  },
  {
    Header: 'IMEI',
    accessor: 'imei',
    required: true
  },
  {
    Header: 'Panic Button Count',
    accessor: 'panicButtonCount'
  },
  {
    Header: 'Customer First Name',
    accessor: 'customerFirstName'
  },
  {
    Header: 'Customer Last Name',
    accessor: 'customerLastName'
  },
  {
    Header: 'Customer Email',
    accessor: 'customerEmail'
  },
  {
    Header: 'Customer Phone',
    accessor: 'customerPhone',
    required: true
  },
  {
    Header: 'Customer Password',
    accessor: 'customerPassword'
  },
  {
    Header: 'Fitter First Name',
    accessor: 'fitterFirstName'
  },
  {
    Header: 'Fitter Last Name',
    accessor: 'fitterLastName'
  },
  {
    Header: 'Fitter Email',
    accessor: 'fitterEmail'
  },
  {
    Header: 'Fitter Phone',
    accessor: 'fitterPhone'
  },
  {
    Header: 'Fitment Date',
    accessor: ({ fitmentDate }) =>
      fitmentDate ? DateTimeService.getDateString(fitmentDate) : ''
  },
  {
    Header: 'Duration In Days',
    accessor: 'durationInDays',
    required: true
  }
];

function ActivationForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const [params] = useQueryParams();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showUserInput, setShowUserInput] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const [showFitterInput, setShowFitterInput] = useState(false);
  const [fitterExists, setFitterExists] = useState(false);

  const [showRCPrev, setShowRCPrev] = useState(false);

  const [RCImages, setRCImages] = useState([]);
  const [deviceImages, setDeviceImages] = useState([]);

  const [aadharCard, setAAdharCard] = useState([]);
  const [panCard, setPanCard] = useState([]);

  const [manualModal, setManualModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [activationData, setActivationData] = useState({});
  const [activations, setActivations] = useState([]);

  const openRCPrev = () => {
    setShowRCPrev(true);
  };

  const closeRCPrev = () => {
    setShowRCPrev(false);
  };

  const { user } = useAuth();

  const toggleShowPassword = () => setShowPassword((prevState) => !prevState);

  const closeModal = () => {
    setModalOpen(false);
    setActivations([]);
  };

  const handleBulkSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = activations.map((activation) => {
        const deviceDetails = {
          imei: activation?.imei,
          panicButtonCount: activation?.panicButtonCount
        };

        const vehicleDetails = {
          category: activation?.category,
          registrationNumber: activation?.registrationNumber,
          engineNo: activation?.engineNo,
          chassisNo: activation?.chassisNo,
          rto: activation?.rto,
          rtoCode: activation?.rtoCode,
          make: activation?.make,
          model: activation?.model
        };

        const customerDetails = {
          firstName: activation?.customerFirstName,
          lastName: activation?.customerLastName,
          email: activation?.customerEmail,
          ...(activation?.customerPhone
            ? { phone: `+91${activation.customerPhone}` }
            : {}),
          password: activation?.customerPassword,
          address: activation?.customerAddress
        };

        const fitterDetails = {
          firstName: activation?.fitterFirstName,
          lastName: activation?.fitterLastName,
          email: activation?.fitterEmail,
          ...(activation?.fitterPhone
            ? { phone: `+91${activation.fitterPhone}` }
            : {})
        };
        return {
          durationInDays: activation?.durationInDays,
          fitmentDate: activation?.fitmentDate,
          fitter: fitterDetails,
          vehicle: vehicleDetails,
          customer: customerDetails,
          device: deviceDetails,
          deviceActivate: activation?.deviceActivate === 'true'
        };
      });
      console.log(payload);

      const { success } = await ActivationService.bulkActivation(payload);

      closeModal();

      navigate(-1);
      enqueueSnackbar(
        success ? 'Activations created successfully' : 'Something went wrong',
        {
          variant: success ? 'success' : 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          TransitionComponent: Zoom
        }
      );
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
    }
  };

  const checkUserWithPhone = useCallback(
    async (setFieldValue, phone, query) => {
      setLoading(true);
      try {
        const customer = await UserService.getUserWithPhone(phone, query);

        const user = await UserService.getUserWithPhone(phone);

        if (!customer && user) {
          toast.error("This is not a customer's number");
          return;
        }

        setShowUserInput(true);
        if (!customer) return;

        setUserExists(true);
        setFieldValue('firstName', customer.firstName);
        setFieldValue('lastName', customer.lastName);
        setFieldValue('email', customer.email);
      } catch (err) {
        toast.error(err?.message || 'Something went wrong!');
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const checkFitterWithPhone = async (setFieldValue, phone, query) => {
    setLoading(true);
    try {
      const user = await UserService.getUserWithPhone(phone, query);

      setShowFitterInput(true);
      if (!user) return;

      setFitterExists(true);
      setFieldValue('ffirstName', user.firstName);
      setFieldValue('flastName', user.lastName);
      setFieldValue('femail', user.email);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!id) return;
      const { data } = await ActivationService.get({ _id: id });
      if (data[0]) {
        const activation = data[0];

        if (activation?.customer) {
          setShowUserInput(true);
        }
        if (activation?.fitter) setShowFitterInput(true);

        setRCImages(
          activation?.vehicle?.rcImages?.map((image) => ({ preview: image })) ||
            []
        );
        setDeviceImages(
          activation?.device?.deviceImages?.map((image) => ({
            preview: image
          })) || []
        );
        setAAdharCard(
          activation?.customer?.kycDocs
            ?.filter((docs) => docs.name === 'AADHAR')
            ?.map((doc) => ({ preview: doc.link }))
        );

        setPanCard(
          activation?.customer?.kycDocs
            ?.filter((docs) => docs.name === 'PAN')
            ?.map((doc) => ({ preview: doc.link }))
        );

        const rtoState = activation?.vehicle?.rtoCode?.split('_')?.[0];

        setActivationData({
          ...activation?.vehicle,
          ...activation?.device,
          ...activation?.customer,
          state: rtoState,
          rtoDetails: `${activation?.vehicle?.rto}-${activation?.vehicle?.rtoCode}`,
          phone: activation?.customer?.phone?.replace('+91', ''),
          ffirstName: activation?.fitter?.firstName,
          flastName: activation?.fitter?.lastName,
          femail: activation?.fitter?.email,
          fphone: activation?.fitter?.phone?.replace('+91', ''),
          durationInDays: Object.keys(duration).find(
            (key) => duration[key] === activation?.durationInDays
          ),
          fitmentDate: new Date(activation?.fitmentDate).toISOString(),
          deviceActivate: activation?.deviceActivate
        });
      }
    })();
  }, [id]);

  console.log(activationData);

  const Compo = (props) => (
    <Card>
      <CardContent>{props.variant}</CardContent>
    </Card>
  );

  return (
    <>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        spacing={4}
      >
        <BulkUploadModal
          title="Create Multiple Activations"
          modalOpen={modalOpen}
          data={activations}
          setData={setActivations}
          tableConfig={tableConfig}
          handleSubmit={handleBulkSubmit}
          setModalOpen={setModalOpen}
          setShowManual={setManualModal}
          closeModal={closeModal}
        />
        <CSVuploadManual
          setModalOpen={setManualModal}
          modalOpen={manualModal}
          gId="466976755"
        />

        <PageHeader
          heading="Fitment"
          subtitle="Fitment"
          Icon={AssignmentTurnedInOutlinedIcon}
        >
          {!id && (
            <Button
              sx={{ ml: 2 }}
              onClick={() => setModalOpen(true)}
              startIcon={<PublishRoundedIcon fontSize="small" />}
            >
              Bulk Upload
            </Button>
          )}
        </PageHeader>

        <Grid
          sx={{
            px: 4
          }}
          container
          direction="row"
          justifyContent="center"
          spacing={4}
        >
          <Grid item xs={12} lg={9}>
            <Card>
              <Formik
                enableReinitialize={id}
                initialValues={{
                  ...(!id ? { password: AppConfig.defaultPass } : {}),
                  deviceActivate: true,
                  fitmentDate: new Date().toISOString(),
                  ...activationData
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={async (
                  values,
                  { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    setSubmitting(true);

                    // if (!RCImages.length) toast.error('RC Images are required');

                    const kycDocs = [
                      ...(aadharCard?.length
                        ? aadharCard.map((file) => ({
                            name: 'AADHAR',
                            link: file.preview
                          }))
                        : []),
                      ...(panCard?.length
                        ? panCard.map((file) => ({
                            name: 'PAN',
                            link: file.preview
                          }))
                        : [])
                    ];

                    // if (!kycDocs.length) toast.error('KYC Docs are required');

                    const deviceDetails = {
                      imei: params?.imei || values?.imei,
                      panicButtonCount: values?.panicButtonCount,
                      deviceImages: deviceImages.map(({ preview }) => preview)
                    };

                    const vehicleDetails = {
                      category: values?.category,
                      registrationNumber: values?.registrationNumber,
                      engineNo: values?.engineNo,
                      chassisNo: values?.chassisNo,
                      rto: values?.rtoDetails?.split('-')?.[0],
                      rtoCode: values?.rtoDetails?.split('-')?.[1],
                      make: values?.make,
                      model: values?.model,
                      regDate: values?.regDate,
                      rcImages: RCImages.map(({ preview }) => preview)
                    };

                    const customerDetails = {
                      firstName: values?.firstName,
                      lastName: values?.lastName,
                      email: values?.email,
                      ...(values?.phone ? { phone: `+91${values.phone}` } : {}),
                      password: values?.password,
                      kycDocs
                    };

                    const fitterDetails = {
                      firstName: values?.ffirstName,
                      lastName: values?.flastName,
                      email: values?.femail,
                      ...(values?.fphone
                        ? { phone: `+91${values.fphone}` }
                        : {})
                    };

                    const payload = {
                      durationInDays: duration[values?.durationInDays],
                      fitmentDate: values?.fitmentDate,
                      deviceActivate: values?.deviceActivate,
                      fitter: fitterDetails,
                      vehicle: vehicleDetails,
                      customer: customerDetails,
                      device: deviceDetails
                    };

                    const { success } = await (id
                      ? ActivationService.put(id, payload)
                      : ActivationService.post(payload));

                    setStatus({ success: true });

                    enqueueSnackbar(
                      id ? 'Fitment Updated' : 'Fitment created',
                      {
                        variant: success ? 'success' : 'error',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center'
                        },
                        TransitionComponent: Zoom
                      }
                    );

                    if (success) {
                      resetForm();
                      navigate(-1);
                    }
                  } catch (err) {
                    console.error(err);
                    enqueueSnackbar(err.message, {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                      },
                      TransitionComponent: Zoom
                    });
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box p={3}>
                      <Typography variant="h4">Fitment Form</Typography>
                    </Box>
                    <Box px={3} py={3}>
                      <Grid container spacing={3}>
                        <SubSection heading="Vehicle Details">
                          <EnumAutocomplete
                            name="category"
                            label="Category"
                            required
                            value={values.category}
                            placeholder="Select Category"
                            onChange={setFieldValue}
                            ENUM={VehicleCategory}
                            lg={6}
                            md={6}
                          />
                          <BrandInput
                            name="registrationNumber"
                            label="Registration Number"
                            required
                            placeholder="Write the Registration Number here..."
                            value={values.registrationNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              touched.registrationNumber &&
                              errors.registrationNumber
                            }
                            error={
                              touched.registrationNumber &&
                              errors.registrationNumber
                            }
                            lg={6}
                            md={6}
                          />
                          <BrandInput
                            name="engineNo"
                            label="Engine No."
                            placeholder="Write the Engine No  here..."
                            value={values.engineNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.engineNo && errors.engineNo}
                            error={touched.engineNo && errors.engineNo}
                            lg={6}
                            md={6}
                          />
                          <BrandInput
                            name="chassisNo"
                            label="Chassis No"
                            placeholder="Write the Chassis No  here..."
                            value={values.chassisNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.chassisNo && errors.chassisNo}
                            error={touched.chassisNo && errors.chassisNo}
                            lg={6}
                            md={6}
                          />

                          <BrandInput
                            name="model"
                            label="Model"
                            placeholder="Write the Model  here..."
                            value={values.model}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.model && errors.model}
                            error={touched.model && errors.model}
                            lg={6}
                            md={6}
                          />

                          <BrandInput
                            name="make"
                            label="Make"
                            placeholder="Write the Make  here..."
                            value={values.make}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.make && errors.make}
                            error={touched.make && errors.make}
                            lg={6}
                            md={6}
                          />

                          <BrandDatePicker
                            name="regDate"
                            label="Vehicle Registration Date"
                            value={values.regDate}
                            required
                            onChange={setFieldValue}
                          />

                          <Grid lg={6} md={6} />

                          <StateAutoComplete
                            name="state"
                            label="State"
                            placeholder="Select the state.."
                            value={values.state}
                            onChange={setFieldValue}
                            required
                            lg={6}
                            md={6}
                          />

                          <RTOAutoComplete
                            name="rtoDetails"
                            label="RTO"
                            placeholder="Select RTO.."
                            state={values.state}
                            value={values.state ? values.rtoDetails : []}
                            onChange={setFieldValue}
                            noOptionsText="Select State First"
                            required
                            lg={6}
                            md={6}
                          />

                          {/* <BrandInput
                            name="rto"
                            label="RTO name"
                            placeholder="Write the RTO Name here..."
                            value={values.rto}
                            onChange={handleChange}
                            required
                            onBlur={handleBlur}
                            helperText={touched.rto && errors.rto}
                            error={touched.rto && errors.rto}
                            lg={6}
                            md={6}
                          />

                          <BrandInput
                            name="rtoCode"
                            label="RTO Code"
                            placeholder="Write the RTO Code here..."
                            value={values.rtoCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.rto && errors.rto}
                            error={touched.rto && errors.rto}
                            lg={6}
                            md={6}
                          /> */}

                          <Grid item lg={12} sm={12}>
                            <Box pb={1}>
                              <b>RC Images</b>{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </Box>
                            <ImageUploader
                              maxFiles={2}
                              minFiles={2}
                              files={RCImages}
                              setFiles={setRCImages}
                            />
                          </Grid>
                        </SubSection>
                        <SubSection heading="Device Details">
                          {!params?.imei && (
                            <IMEIAutoComplete
                              label="IMEI"
                              filter={{
                                statusNotIn: 'INSTALLED'
                              }}
                              value={values.imei}
                              isDisabled={params?.imei}
                              onChange={setFieldValue}
                              name="imei"
                              lg={6}
                              md={6}
                            />
                          )}

                          <BrandInput
                            name="panicButtonCount"
                            type="number"
                            label="Panic Button Count"
                            placeholder="Write the panic button count here..."
                            value={values.panicButtonCount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              touched.panicButtonCount &&
                              errors.panicButtonCount
                            }
                            error={
                              touched.panicButtonCount &&
                              errors.panicButtonCount
                            }
                            lg={6}
                            md={6}
                          />

                          {params?.imei && (
                            <BrandInput
                              name="imei"
                              label="IMEI"
                              type="number"
                              placeholder="Write the imei No. here..."
                              value={params?.imei || values.imei}
                              isDisabled={params?.imei}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={touched.imei && errors.imei}
                              error={touched.imei && errors.imei}
                              lg={12}
                              md={12}
                            />
                          )}
                          <FormControlLabel
                            sx={{ pl: 2, fontWeight: 'bold' }}
                            value="start"
                            control={
                              <Stack
                                pl={2}
                                direction="row"
                                spacing={0}
                                alignItems="center"
                              >
                                <Typography>NO</Typography>
                                <Switch
                                  checked={values.deviceActivate}
                                  onChange={handleChange}
                                  name="deviceActivate"
                                />
                                <Typography>YES</Typography>
                              </Stack>
                            }
                            label={
                              <Typography sx={{ fontWeight: 'bold' }}>
                                <b style={{ color: 'red' }}>*</b> Do You Want To
                                Activate Tracking on this device ?
                              </Typography>
                            }
                            labelPlacement="start"
                          />
                        </SubSection>
                        <Grid item lg={12} sm={12}>
                          <Box pb={1}>
                            <b>Device Images</b>{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Box>
                          <ImageUploader
                            maxFiles={3}
                            minFiles={2}
                            files={deviceImages}
                            setFiles={setDeviceImages}
                          />
                        </Grid>
                        <SubSection heading="Customer Details">
                          {userExists && (
                            <Grid item md={12} lg={12} sm={12}>
                              <Button onClick={() => setUserExists(false)}>
                                Edit
                              </Button>
                            </Grid>
                          )}
                          <Grid item md={12} lg={12} sm={12}>
                            <Collapse in={showUserInput}>
                              <Grid container spacing={3}>
                                <BrandInput
                                  isDisabled={userExists}
                                  name="firstName"
                                  label="First Name"
                                  required
                                  placeholder="Write the first name here..."
                                  value={values.firstName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touched.firstName && errors.firstName
                                  }
                                  error={touched.firstName && errors.firstName}
                                  lg={6}
                                  md={6}
                                />
                                <BrandInput
                                  isDisabled={userExists}
                                  name="lastName"
                                  label="Last Name"
                                  required
                                  placeholder="Write the last name here..."
                                  value={values.lastName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touched.lastName && errors.lastName
                                  }
                                  error={touched.lastName && errors.lastName}
                                  md={6}
                                  lg={6}
                                />
                              </Grid>
                            </Collapse>
                          </Grid>
                          {!showUserInput && (
                            <>
                              <BrandInput
                                isDisabled={userExists}
                                name="phone"
                                label="Phone"
                                required
                                placeholder="Write the phone number  here..."
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={touched.phone && errors.phone}
                                error={touched.phone && errors.phone}
                                lg={12}
                                md={12}
                                InputProps={{
                                  inputProps: { maxLength: 10 },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      +91
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <IconButton
                                      sx={{
                                        '&.Mui-disabled .MuiSvgIcon-root': {
                                          color: 'grey'
                                        }
                                      }}
                                      disabled={values?.phone?.length !== 10}
                                      onClick={() =>
                                        checkUserWithPhone(
                                          setFieldValue,
                                          values.phone,
                                          {
                                            roleName: 'LIMITED_USER'
                                          }
                                        )
                                      }
                                    >
                                      {!loading ? (
                                        <PersonSearchIcon color="primary" />
                                      ) : (
                                        <CircularProgress size="1rem" />
                                      )}
                                    </IconButton>
                                  )
                                }}
                              />
                            </>
                          )}

                          <Grid item md={12} lg={12} sm={12}>
                            <Collapse in={showUserInput} component={Grid}>
                              <Grid container spacing={3}>
                                <BrandInput
                                  isDisabled={userExists}
                                  name="phone"
                                  label="Phone"
                                  required
                                  placeholder="Write the phone number here..."
                                  value={values.phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    inputProps: {
                                      maxLength: 10
                                    },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        +91
                                      </InputAdornment>
                                    )
                                  }}
                                  helperText={touched.phone && errors.phone}
                                  error={touched.phone && errors.phone}
                                  lg={6}
                                  md={6}
                                />
                                <BrandInput
                                  isDisabled={userExists}
                                  name="email"
                                  label="Email"
                                  type="email"
                                  required
                                  placeholder="Write the email here..."
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={touched.email && errors.email}
                                  error={touched.email && errors.email}
                                  lg={6}
                                  md={6}
                                />

                                <Grid item xs={12} md={12}>
                                  <BrandInput
                                    disabled={userExists}
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    label="Custom Password"
                                    value={values?.password}
                                    placeholder="Write a password here..."
                                    onChange={handleChange}
                                    InputProps={{
                                      endAdornment: (
                                        <IconButton
                                          onClick={
                                            !userExists && toggleShowPassword
                                          }
                                        >
                                          {showPassword ? (
                                            <VisibilityOffRoundedIcon />
                                          ) : (
                                            <VisibilityRoundedIcon />
                                          )}
                                        </IconButton>
                                      )
                                    }}
                                  />
                                </Grid>

                                <BrandInput
                                  isDisabled={userExists}
                                  name="customerAddress"
                                  label="Customer Address"
                                  required
                                  placeholder="Customer Address"
                                  value={values.customerAddress}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touched.customerAddress &&
                                    errors.customerAddress
                                  }
                                  error={
                                    touched.customerAddress &&
                                    errors.customerAddress
                                  }
                                  md={12}
                                  lg={12}
                                  multiline
                                  minRows={3}
                                />
                              </Grid>
                            </Collapse>
                          </Grid>
                          <Grid item lg={12} sm={12}>
                            <Box pb={1}>
                              <b>CUSTOMER KYC DOCS</b>
                            </Box>
                            <Stack direction="row" gap={2}>
                              <Box>
                                <Box pb={1}>
                                  <b>Adhaar Card</b>
                                </Box>
                                <ImageUploader
                                  maxFiles={2}
                                  minFiles={1}
                                  files={aadharCard}
                                  setFiles={setAAdharCard}
                                />
                              </Box>

                              <Box>
                                <Box pb={1}>
                                  <b>PAN Card</b>
                                </Box>
                                <ImageUploader
                                  maxFiles={2}
                                  minFiles={1}
                                  files={panCard}
                                  setFiles={setPanCard}
                                />
                              </Box>
                            </Stack>
                          </Grid>
                        </SubSection>
                        <SubSection heading="Fitter Details">
                          {fitterExists && (
                            <Grid item md={12} lg={12} sm={12}>
                              <Button onClick={() => setFitterExists(false)}>
                                Edit
                              </Button>
                            </Grid>
                          )}
                          <Grid item md={12} lg={12} sm={12}>
                            <Collapse in={showFitterInput}>
                              <Grid container spacing={3}>
                                <BrandInput
                                  isDisabled={fitterExists}
                                  name="ffirstName"
                                  label="First Name"
                                  placeholder="Write the first name here..."
                                  value={values.ffirstName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touched.ffirstName && errors.ffirstName
                                  }
                                  error={
                                    touched.ffirstName && errors.ffirstName
                                  }
                                  lg={6}
                                  md={6}
                                />
                                <BrandInput
                                  isDisabled={fitterExists}
                                  name="flastName"
                                  label="Last Name"
                                  placeholder="Write the last name here..."
                                  value={values.flastName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    touched.flastName && errors.flastName
                                  }
                                  error={touched.flastName && errors.flastName}
                                  md={6}
                                  lg={6}
                                />
                              </Grid>
                            </Collapse>
                          </Grid>
                          {!showFitterInput && (
                            <>
                              <BrandInput
                                isDisabled={fitterExists}
                                name="fphone"
                                label="Phone"
                                placeholder="Write the phone number  here..."
                                value={values.fphone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={touched.fphone && errors.fphone}
                                error={touched.fphone && errors.fphone}
                                lg={12}
                                md={12}
                                InputProps={{
                                  inputProps: { maxLength: 10 },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      +91
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <IconButton
                                      sx={{
                                        '&.Mui-disabled .MuiSvgIcon-root': {
                                          color: 'grey'
                                        }
                                      }}
                                      disabled={values?.fphone?.length !== 10}
                                      onClick={() =>
                                        checkFitterWithPhone(
                                          setFieldValue,
                                          values.fphone,
                                          {
                                            roleName: 'FITTER'
                                          }
                                        )
                                      }
                                    >
                                      {!loading ? (
                                        <PersonSearchIcon color="primary" />
                                      ) : (
                                        <CircularProgress size="1rem" />
                                      )}
                                    </IconButton>
                                  )
                                }}
                              />
                            </>
                          )}
                          <Grid item md={12} lg={12} sm={12}>
                            <Collapse in={showFitterInput} component={Grid}>
                              <Grid container spacing={3}>
                                <BrandInput
                                  isDisabled={fitterExists}
                                  name="fphone"
                                  label="Phone"
                                  placeholder="Write the phone number here..."
                                  value={values.fphone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    inputProps: { maxLength: 10 },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        +91
                                      </InputAdornment>
                                    )
                                  }}
                                  helperText={touched.fphone && errors.fphone}
                                  error={touched.fphone && errors.fphone}
                                  lg={6}
                                  md={6}
                                />
                                <BrandInput
                                  isDisabled={fitterExists}
                                  name="femail"
                                  label="Email"
                                  type="email"
                                  placeholder="Write the email here..."
                                  value={values.femail}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={touched.femail && errors.femail}
                                  error={touched.femail && errors.femail}
                                  lg={6}
                                  md={6}
                                />
                              </Grid>
                            </Collapse>
                          </Grid>
                        </SubSection>

                        {/* <DeviceAutoComplete
                        value={values.device}
                        onChange={setFieldValue}
                        filter={{
                          isTrackingActivated: false,
                          isSimActivated: true
                        }}
                      /> */}
                        <BrandDatePicker
                          label="Fitment Date"
                          required
                          value={values.fitmentDate}
                          onChange={setFieldValue}
                          name="fitmentDate"
                          lg={12}
                          md={12}
                        />
                        <EnumAutocomplete
                          ENUM={Object.keys(duration)}
                          required
                          value={values.durationInDays}
                          name="durationInDays"
                          label="Activation Duration"
                          onChange={setFieldValue}
                        />
                      </Grid>
                    </Box>

                    <CardActionsWrapper
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        p: 2
                      }}
                    >
                      <Box>
                        <Button
                          variant="contained"
                          type="submit"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size="1rem" />
                            ) : null
                          }
                          disabled={isSubmitting}
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Box>
                    </CardActionsWrapper>
                  </form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ActivationForm;
