import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const TotalRunSummaryModule = new Module({
  id: 'total-run-summary',
  name: 'Total Run',
  path: 'total-run-summary',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/TotalRunSummary'))
  )
});
