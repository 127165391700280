import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const TripByIgnitionModule = new Module({
  id: 'trip-by-ignition',
  name: 'Daily Vehicle Operations',
  path: 'daily-vehicle-operation-report',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/TripByIgnition'))
  )
});
